import React from 'react';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Select from 'react-select';
import { Toaster } from 'react-hot-toast';
import FundortMap from './FundortMap.js';
import { SelectFundort } from './SelectFundort.js';
import FotoUpload from './FotoUpload.js';
import InputArt from '../Inputs/InputArt.js';
import DatePickerField from '../Inputs/DatePicker.js';
import NahrungspflanzenSelect from './NahrungspflanzenSelect.js';
import { InputAnzahl, InputStadium } from './Inputs.js';
import UnschaerfeInput from './UnschaerfeInput.js';
import choicesArtDerBeobachtung from "../../data/choices_art_der_beobachtung.json";
import choicesBeleg from "../../data/choices_beleg.json";
import choicesBestimmungsmethode from "../../data/choices_bestimmungsmethode.json";
import choicesZustand from "../../data/choices_zustand.json";
import choicesDatenquelle from "../../data/choices_datenquelle.json";
import choicesBestimmungssicherheit from "../../data/choices_bestimmungssicherheit.json";

export function NeueBeobachtungUI({ values, handleSubmit, handleChange, handleBlur, setFieldValue,
  map, setMap, files, setFiles, buttonDisabled, imgCollection, setImgCollection, setSource,
  id, edit, drawLayer, mapCenter, mapZoom, createdBy
}) {

  return (
    <>
      <Toaster position="bottom-right" />
      <br></br>
      <Row>
        <Col md={12} lg={6} className='ms-lg-2'>
          <Container className="panel">
            <Form>
              <Row>
                <Form.Group as={Col} xs={12} sm={6} md={4}>
                  <Form.Label>Datum der Beobachtung</Form.Label>
                  <br></br>
                  <DatePickerField
                    name="datum"
                    placeholderText="Datum auswählen..." />
                </Form.Group>
                <Form.Group as={Col} xs={12} sm={6} md={4}>
                  <Form.Label>Beobachter</Form.Label>
                  <Form.Control
                    id={"beobachter"}
                    type={'text'}
                    value={values.beobachter}
                    onBlur={handleBlur}
                    onChange={handleChange} />
                </Form.Group>
                <Form.Group as={Col} xs={12} sm={6} md={4}>
                  <Form.Label>Begleiter</Form.Label>
                  <Form.Control
                    id={"begleiter"}
                    type="text"
                    placeholder="Begleiter"
                    value={values.begleiter}
                    onChange={handleChange} />
                </Form.Group>
              </Row>
            </Form>
            <br></br>
            <FundortMap
              mapCenter={mapCenter}
              mapZoom={mapZoom}
              setFieldValue={setFieldValue}
              setMap={setMap}
              values={values}
              drawLayer={drawLayer}
            />
            <Row>
              <Col xs={9}>
                <SelectFundort
                  id={id}
                  values={values}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  map={map}
                  edit={edit}
                  drawLayer={drawLayer}
                  createdBy={createdBy}
                />
              </Col>
              <Col xs={3}>
                <UnschaerfeInput
                  values={values}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  drawLayer={drawLayer}
                />
              </Col>
            </Row>
          </Container>
          <br></br>
          {/* <div>Coordinates: {coordinates.join(', ')}</div> */}
          <Container className="panel">
            <Row>
              <Form.Group as={Col} xs={12} sm={6} md={4}>
                <Form.Label>Bestimmer</Form.Label>
                <Form.Control
                  id={"bestimmer"}
                  type="text"
                  placeholder="Bestimmer"
                  value={values.bestimmer}
                  onChange={handleChange} />
              </Form.Group>
              <Form.Group as={Col} xs={12} sm={6} md={4}>
                <Form.Label>Bestimmungssicherheit</Form.Label>
                <Select
                  id={"bestimmungssicherheit"}
                  type={"text"}
                  value={values.bestimmungssicherheit}
                  onChange={option => {
                    setFieldValue("bestimmungssicherheit", option);
                  }}
                  options={choicesBestimmungssicherheit}
                  onBlur={handleBlur} />
              </Form.Group>
              <Form.Group as={Col} xs={12} sm={6} md={4}>
                <Form.Label>Bestimmungsmethode</Form.Label>
                <Select
                  id={"bestimmungsmethode"}
                  type={"text"}
                  value={values.bestimmungsmethode}
                  onChange={option => {
                    setFieldValue("bestimmungsmethode", option);
                  }}
                  options={choicesBestimmungsmethode}
                  onBlur={handleBlur} />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} xs={12} sm={6} md={4}>
                <Form.Label>Beleg</Form.Label>
                <Select
                  id={"beleg"}
                  type={"text"}
                  value={values.beleg}
                  onChange={option => {
                    setFieldValue("beleg", option);
                  }}
                  options={choicesBeleg}
                  onBlur={handleBlur} />
              </Form.Group>
              <Form.Group as={Col} xs={12} sm={6} md={4}>
                <Form.Label>Art der Beobachtung</Form.Label>
                <Select
                  id={"art_der_beobachtung"}
                  type={"text"}
                  value={values.art_der_beobachtung}
                  onChange={option => {
                    setFieldValue("art_der_beobachtung", option);
                  }}
                  options={choicesArtDerBeobachtung}
                  onBlur={handleBlur} />
              </Form.Group>
              <Form.Group as={Col} xs={12} sm={6} md={4}>
                <Form.Label>Datenquelle</Form.Label>
                <Select
                  id={"datenquelle"}
                  type={"text"}
                  value={values.datenquelle}
                  onChange={option => {
                    setFieldValue("datenquelle", option);
                  }}
                  options={choicesDatenquelle}
                  onBlur={handleBlur} />
              </Form.Group>
            </Row>
            <br></br>
            <Row>
              <Form.Group as={Col} xs={12}>
                <Form.Control
                  id={"datenquelle_bemerkung"}
                  as="textarea"
                  rows="2"
                  style={{ height: "100%" }}
                  placeholder="Bemerkung zur Datenquelle"
                  value={values.datenquelle_bemerkung}
                  onChange={handleChange} />
              </Form.Group>
            </Row>
          </Container>
          <br></br>
        </Col>
        <Col className='me-lg-2'>
          <Container className="panel">
            <FotoUpload
              exif={!edit}
              files={files}
              values={values}
              setFieldValue={setFieldValue}
              map={map}
              drawLayer={drawLayer}
              imgCollection={imgCollection}
              setImgCollection={setImgCollection}
              setFiles={setFiles}
              setSource={setSource}
            />
            <hr></hr>
            <Form onSubmit={handleSubmit}>
              <Row>
                <Form.Group as={Col} xs={12} sm={6} md={6}>
                  <Form.Label>Art</Form.Label>
                  <InputArt name="art"></InputArt>
                  <br></br>
                  <InputStadium
                    setFieldValue={setFieldValue}
                    values={values}
                    handleChange={handleChange}
                    handleBlur={handleBlur} />
                  <br></br>
                  <Row>
                    <InputAnzahl
                      values={values}
                      handleChange={handleChange}
                      handleBlur={handleBlur} />
                  </Row>
                </Form.Group>
                <Form.Group as={Col} xs={12} sm={6} md={6}>
                  <Form.Control
                    id={"bemerkungen"}
                    as="textarea"
                    rows="7"
                    style={{ height: "100%" }}
                    placeholder="Bemerkungen"
                    value={values.bemerkungen}
                    onChange={handleChange} />
                </Form.Group>
              </Row>
              <hr></hr>
              <Row>
                <Form.Group as={Col} xs={12} sm={6} md={6}>
                  <NahrungspflanzenSelect
                    stadium={values.stadium}
                    values={values}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur} />
                </Form.Group>
                <Form.Group as={Col} xs={12} sm={6} md={6}>
                  <Form.Label>Zustand</Form.Label>
                  <Select
                    id={"zustand"}
                    type={"text"}
                    value={values.zustand}
                    onChange={option => setFieldValue("zustand", option)}
                    options={choicesZustand}
                    onBlur={handleBlur} />
                </Form.Group>
              </Row>
              <hr></hr>
              <Button
                variant="success"
                type="submit"
                disabled={buttonDisabled}
                className='w-100'
              >
                Beobachtung speichern
              </Button>
            </Form>
          </Container>
        </Col>
      </Row>
    </>
  );
}
