import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faUser } from "@fortawesome/free-solid-svg-icons";
import { Accordion } from "react-bootstrap";
import BeobachtungHeader from "./BeobachtungHeader";
import BeobachtungImages from "./BeobachtungImages";
import BeobachtungDetails from "./BeobachtungDetails";
import FundortTabelle from "./FundortTabelle";
import TK25Tabelle from "./TK25Tabelle";
import toGermanDate from "../../functions/toGermanDate";

export default function BeobachtungPrivateMobile({ observation, show, handleShow,
  handleClose, handleDelete, handleEdit, map, mapLinks, regionTable, naturraumTable, coordTable }) {

  return (
    <>
      <Container style={{ paddingLeft: "0px", paddingRight: "0px" }}>
        <Row>
          <Col md={6} xs={12}>
            {/* <br></br> */}
            <Container style={{ padding: "10px" }} className="panel">
              <BeobachtungHeader observation={observation[0]} show={show} handleShow={handleShow} handleClose={handleClose} handleDelete={handleDelete} handleEdit={handleEdit} map={map} mapLinks={mapLinks} regionTable={regionTable} naturraumTable={naturraumTable} />
            </Container>
            {/* <hr></hr> */}
            <BeobachtungImages observation={observation} />
            <Container className="panel" style={{ paddingTop: "10px", paddingLeft: "5px", paddingRight: "5px", paddingBottom: "0px" }}>
              <Row>
                <Col xs={6}>
                  <FontAwesomeIcon icon={faUser} />{" "}{observation[0].beobachter}
                </Col>
                <Col xs={6}>
                  <FontAwesomeIcon icon={faCalendarAlt} />{" "} {toGermanDate(observation[0].datum)}
                </Col>
              </Row>
              <hr></hr>
              <Table responsive hover size="sm">
                <thead>
                  <tr>
                    <th>Stadium</th>
                    <th>Anzahl</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{observation[0].stadium}</td>
                    <td>{observation[0].anzahl}
                      {
                        observation[0].stadium === "Imago" && (observation[0].anzahl_m > 0 || observation[0].anzahl_w > 0) ?
                          <small>
                            {" (davon "}{observation[0].anzahl_m > 0 ? <>
                              {observation[0].anzahl_m}{" "}{String.fromCharCode(9794)}
                            </> : null}
                            {observation[0].anzahl_m > 0 && observation[0].anzahl_w > 0 ? <>
                              {" und "}
                            </> : null}
                            {observation[0].anzahl_w > 0 ? <>
                              {observation[0].anzahl_w}{" "}{String.fromCharCode(9792)}
                            </> : null}
                            )
                          </small> :
                          <></>
                      }
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Container>
            {map}
            {mapLinks}
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Fundort Details</Accordion.Header>
                <Accordion.Body>
                  {coordTable}
                  {regionTable}
                  {naturraumTable}
                  <TK25Tabelle props={observation[0]} />
                  <FundortTabelle props={observation[0]} />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Container className="panel" style={{ paddingLeft: "5px", paddingRight: "5px" }}>
              <BeobachtungDetails observation={observation} />
            </Container>
          </Col>
        </Row>
        {/* <br></br> */}
      </Container>
    </>
  )
}
