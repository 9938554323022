import React from "react";
import { MapContainer, Marker, Rectangle, Tooltip, Circle } from 'react-leaflet';
import LayerControl from './LayerControl';
import { FullscreenControl } from "react-leaflet-fullscreen";


export default function MarkerMap(props) {

  const doNothing = () => void 0;
  const setMap = props.setMap ?? doNothing;

  return (
    <MapContainer
      className={props.className}
      center={[props.observation.latitude, props.observation.longitude]}
      zoom={15}
      scrollWheelZoom={true}
      whenCreated={(map) => setMap(map)}
      minZoom={6}
    >
      <LayerControl></LayerControl>
      <Marker
        position={[props.observation.latitude, props.observation.longitude]}
      />
      {props.observation.unschaerfe ?
        <Circle
          center={[props.observation.latitude, props.observation.longitude]}
          radius={props.observation.unschaerfe}
          pathOptions={{
            color: "grey",
            fillColor: "grey",
            fillOpacity: 0.2,
            opacity: 0.5,
            weight: 1
          }}
        /> : null}
      <FullscreenControl position="topleft" />
    </MapContainer>
  )
}
