import React from "react";
import proj4 from 'proj4';
proj4.defs("EPSG:32632", "+proj=utm +zone=32 +datum=WGS84 +units=m +no_defs +type=crs");

function WGS84ToUTM(lng, lat) {
  return proj4(new proj4.Proj('EPSG:32632'), [lng, lat]);
}

export default function KoordinatenLinks({ latitude, longitude, bundesland }){
  const latitudeNum = Number(latitude);
  const longitudeNum = Number(longitude);
  const latitudeRounded = latitudeNum.toFixed(5);
  const longitudeRounded = longitudeNum.toFixed(5);
  const urlGoogle = `https://www.google.com/maps?q=${latitudeRounded},${longitudeRounded}`;
  const urlOSM = `https://www.openstreetmap.org/?mlat=${latitudeRounded}&mlon=${longitudeRounded}#map=16/${latitudeRounded}/${longitudeRounded}`;
  const urlBing = `https://www.bing.com/maps?cp=${latitudeRounded}~${longitudeRounded}&lvl=16&style=r&sp=point.${latitudeRounded}_${longitudeRounded}_Marker&style=h`;
  let spanBayernAtlas = null;

  if (bundesland === "Bayern") {
    const utmCoords = WGS84ToUTM(longitudeNum, latitudeNum);
    const urlBayernAtlas = `https://geoportal.bayern.de/bayernatlas/?lang=de&topic=ba&bgLayer=atkis&catalogNodes=11&E=${utmCoords[0]}&N=${utmCoords[1]}&zoom=11&crosshair=marker`;
    spanBayernAtlas = (
      <span>
        {" · "}
        <a href={urlBayernAtlas} rel="noopener noreferrer" target="_blank">
          Bayern Atlas
        </a>
      </span>
    );
  }

  return (
    <small>
      <span>{"Ansehen auf "}
        <a href={urlOSM} rel="noopener noreferrer" target="_blank">
          OSM
        </a>
      </span>{" · "}
      <span>
        <a href={urlGoogle} rel="noopener noreferrer" target="_blank">
          Google Maps
        </a>
      </span>{" · "}
      <span>
        <a href={urlBing} rel="noopener noreferrer" target="_blank">
          Bing
        </a>
      </span>
      {spanBayernAtlas}
    </small>
  );
}