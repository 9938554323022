import React from 'react';
import { Table } from 'react-bootstrap';
import toGermanDate from "../../functions/toGermanDate";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCalendarAlt, faPen, faListNumeric, faBug } from '@fortawesome/free-solid-svg-icons';

export default function BeobachtungDetails({ observation }) {
  return (
    <>
      <h5>Details</h5>
      <Table responsive hover size="sm">
        <tbody>
          <tr className="table-primary">
            <th><FontAwesomeIcon icon={faUser} /> Beobachter</th>
            <td>{observation[0].beobachter}</td>
          </tr>
          <tr className="table-primary">
            <th><FontAwesomeIcon icon={faCalendarAlt} /> Datum</th>
            <td>{toGermanDate(observation[0].datum)}</td>
          </tr>
          <tr className="table-primary">
            <th><FontAwesomeIcon icon={faListNumeric} /> Anzahl</th>
            <td>{observation[0].anzahl}
              {
                observation[0].stadium === "Imago" && (observation[0].anzahl_m > 0 || observation[0].anzahl_w > 0) ?
                  <small>
                    {" (davon "}{observation[0].anzahl_m > 0 ? <>
                      {observation[0].anzahl_m}{" "}{String.fromCharCode(9794)}
                    </> : null}
                    {observation[0].anzahl_m > 0 && observation[0].anzahl_w > 0 ? <>
                      {" und "}
                    </> : null}
                    {observation[0].anzahl_w > 0 ? <>
                      {observation[0].anzahl_w}{" "}{String.fromCharCode(9792)}
                    </> : null}
                  </small> :
                  <></>
              }
            </td>
          </tr>
          <tr className="table-primary">
            <th><FontAwesomeIcon icon={faBug} /> Stadium</th>
            <td>{observation[0].stadium}</td>
          </tr>
          <tr className="table-primary">
          <th><FontAwesomeIcon icon={faPen} /> Bemerkungen</th>
            <td>{observation[0].bemerkungen}</td>
          </tr>
          <tr>
            <th>Zustand</th>
            <td>{observation[0].zustand}</td>
          </tr>
          {
            observation[0].stadium === "Imago" ?
              <tr>
                <th>Nektarpflanzen</th>
                <td><i>{observation[0].nektarpflanzen}</i></td>
              </tr> :
              <tr>
                <th>Larvalnahrung</th>
                <td><i>{observation[0].larvalnahrung}</i></td>
              </tr>
          }
          <tr>
            <th>Art der Beobachtung</th>
            <td>{observation[0].art_der_beobachtung}</td>
          </tr>
          <tr>
            <th>Beleg</th>
            <td>{observation[0].beleg}</td>
          </tr>
          <tr>
            <th>Bestimmer</th>
            <td>{observation[0].bestimmer}</td>
          </tr>
          <tr>
            <th>Bestimmungssicherheit</th>
            <td>{observation[0].bestimmungssicherheit}</td>
          </tr>
          <tr>
            <th>Bestimmungsmethode</th>
            <td>{observation[0].bestimmungsmethode}</td>
          </tr>
          <tr>
            <th>Datenquelle</th>
            <td>{observation[0].datenquelle}</td>
          </tr>
          {observation[0].datenquelle_bemerkung ? <tr>
            <th>Datenquelle (Bemerkung)</th>
            <td>{observation[0].datenquelle_bemerkung}</td>
          </tr> : null}
          {observation[0].erfassungsart ? <tr>
            <th>Erfassungsart</th>
            <td>{observation[0].erfassungsart}</td>
          </tr> : null}
          {observation[0].datenquelle_import ? <tr>
            <th>Datenquelle (Import)</th>
            <td>{observation[0].datenquelle_import}</td>
          </tr> : null}
          {observation[0].datenbank ? <tr>
            <th>Datenbank</th>
            <td>{observation[0].datenbank}</td>
          </tr> : null}
          {observation[0].url ? <tr>
            <th>URL</th>
            <td><a rel="noopener noreferrer" target="_blank" href={observation[0].url} >{observation[0].url}</a></td>
          </tr> : null}
          {observation[0].datenquelle_bemerkung ? <tr>
            <th>ID Extern</th>
            <td>{observation[0].id_extern}</td>
          </tr> : null}
          <tr>
            <th>Erfasser</th>
            <td>{observation[0].erfasser}</td>
          </tr>
          <tr>
            <th><FontAwesomeIcon icon={faCalendarAlt} /> Erstellt am</th>
            <td>{toGermanDate(observation[0].created_at)}</td>
          </tr>
          <tr>
            <th>ID</th>
            <td>{observation[0].id}</td>
          </tr>
        </tbody>
      </Table>
    </>
  )
}