import React from 'react';
import ImageGallery from 'react-image-gallery';
import { SRLWrapper } from 'simple-react-lightbox';
import SimpleReactLightbox from 'simple-react-lightbox';
import "react-image-gallery/styles/css/image-gallery.css";

export default function BeobachtungImages({ observation }) {
  // div that contains images if there are any
  let imagePlaceholder;
  if (observation !== null && observation.length > 0) {
    // let nImages = observation.length;
    let images = observation.map(function (observation) {
      return (
        {
          original: observation.foto,
          thumbnail: observation.foto
        }
      )
    });

    if (observation[0].foto !== null) {
      imagePlaceholder = <>
        {
          observation.length > 1 ?
            <ImageGallery items={images} showNav={false} showPlayButton={false} slideDuration={0} /> :
            <SimpleReactLightbox>
              <SRLWrapper>
                <img src={observation[0].foto} alt={observation[0].art}></img>
              </SRLWrapper>
            </SimpleReactLightbox>
          // <img src={observation.foto} alt={observation.art}></img>
        }
      </>
    } else {
      imagePlaceholder = <></>
    }
  }

  return (
    <>
      {imagePlaceholder}
    </>
  );
}
