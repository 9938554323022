import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import FundortTabelle from "./FundortTabelle";
import TK25Tabelle from "./TK25Tabelle";
import BeobachtungHeader from "./BeobachtungHeader";
import BeobachtungImages from "./BeobachtungImages";
import BeobachtungDetails from "./BeobachtungDetails";


export default function BeobachtungPrivateDesktop({ observation, show, handleShow,
  handleClose, handleDelete, handleEdit, map, mapLinks, regionTable, naturraumTable, coordTable }) {

  return (
    <>
      <Container>
        <Row>
          <Col md={6} xs={12}>
            <br></br>
            <Container className="panel">
              <BeobachtungHeader
                observation={observation[0]}
                show={show} handleShow={handleShow}
                handleClose={handleClose}
                handleDelete={handleDelete}
                handleEdit={handleEdit}
                map={map}
                mapLinks={mapLinks}
                regionTable={regionTable}
                naturraumTable={naturraumTable}
              />
              <BeobachtungImages observation={observation} />
            </Container>
            <br></br>
            <Container className="panel">
              <BeobachtungDetails observation={observation} />
            </Container>
          </Col>
          <Col md={6} xs={12}>
            <br></br>
            <Container className="panel">
              {map}
              {mapLinks}
              {coordTable}
              {regionTable}
              {naturraumTable}
              <TK25Tabelle props={observation[0]} />
              <FundortTabelle props={observation[0]} />
            </Container>
          </Col>
        </Row>
        <br></br>
      </Container>
    </>
  )
}
