import React from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import FreigabeIcon from "../FreigabeIcon";
import { Toaster } from 'react-hot-toast';

export default function BeobachtungHeader({ observation, show, handleShow, handleClose, handleDelete, handleEdit }) {
  return (
    <>
      <h3>
        <i>{observation.art}</i>{" "}
        <FreigabeIcon observation={observation}>
        </FreigabeIcon>{" "}
        <Button
          title="Beobachtung löschen"
          onClick={() => handleShow()}
          style={{ float: "right"}}
          variant="danger"
        >
          <i className="fa fa-trash"></i>
        </Button>
        <Button
          title="Beobachtung editieren"
          onClick={() => handleEdit()}
          style={{ float: "right", marginRight: "5px" }}
          variant="warning"
        >
          <i className="fa fa-pencil"></i>
        </Button>
      </h3>
      <h5 className="color-grey"> {observation.deutscher_name}</h5>
      <Toaster position="bottom-right" />
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Beobachtung wirklich löschen?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Dies kann nicht rückgängig gemacht werden!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Abbrechen
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Ja, Beobachtung löschen
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
