import React from "react";
import KoordinatenLinks from "./KoordinatenLinks";
import KoordinatenDetails from "./KoordinatenDetails";

export default function KoordinatenTabelle({ latitude, longitude, hoehe, unschaerfe, bundesland }) {

  return (
    <>
      <KoordinatenLinks
        latitude={latitude}
        longitude={longitude}
        bundesland={bundesland}
      />
      <br></br>
      <KoordinatenDetails
        latitude={latitude}
        longitude={longitude}
        hoehe={hoehe}
        unschaerfe={unschaerfe}
      />
    </>
  )
}
