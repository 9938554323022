import React, { useState } from "react";
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import axios from '../AxiosConfig';
import BeobachtungPrivateDesktop from './BeobachtungPrivateDesktop';
import BeobachtungPrivateMobile from './BeobachtungPrivateMobile';
import NaturraumTabelle from './NaturraumTabelle';
import RegionTabelle from './RegionTabelle';
import MarkerMap from '../Map/MarkerMap';
import BaseMap from '../Map/BaseMap';
import Loading from '../Loading';
import KoordinatenLinks from "./KoordinatenLinks";
import KoordinatenDetails from "./KoordinatenDetails";


export default function BeobachtungPrivate({ observation }) {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const id = observation[0].id;

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();
  const handleDelete = () => {
    console.log("handleDelete");
    axios.post(
      `observation/delete/`,
      {
        id: id
      }
    ).then(response => {
      console.log("deleted observation");
      console.log(response);
      handleClose();
      navigate('/meinebeobachtungen', { replace: true });
      toast.success("Beobachtung wurde gelöscht!");
    }).catch(function (error) {
      console.log(error);
      toast.error("Es ist ein Fehler aufgetreten!");
    });
  };

  const handleEdit = () => {
    console.log("handleEdit");
    navigate("/edit?id=".concat(id), { replace: true });
  };

  let mapLinks = null;
  if (observation[0].latitude !== null) {
    mapLinks = <KoordinatenLinks
      latitude={observation[0].latitude}
      longitude={observation[0].longitude}
      bundesland={observation[0].bundesland}
    />
  }

  let naturraumTable = null;
  if (observation[0].naturraum_name !== null) {
    naturraumTable = <NaturraumTabelle props={observation[0]} />
  }

  let regionTable = null;
  if (observation[0].landkreis !== null) {
    regionTable = <RegionTabelle props={observation[0]} />
  }

  let coordTable = null;
  if (observation[0].latitude !== null) {
    coordTable = <KoordinatenDetails
      latitude={observation[0].latitude}
      longitude={observation[0].longitude}
      hoehe={observation[0].hoehe}
      unschaerfe={observation[0].unschaerfe}
    />
  }

  const map = observation[0].latitude !== null ?
    <MarkerMap observation={observation[0]} className="map-small"></MarkerMap> :
    <BaseMap observation={observation[0]} className="map-small"></BaseMap>

  return (
    <>
      {observation ? (
        isMobile ? (
          <BeobachtungPrivateMobile
            observation={observation}
            show={show}
            handleShow={handleShow}
            handleClose={handleClose}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
            map={map}
            mapLinks={mapLinks}
            regionTable={regionTable}
            naturraumTable={naturraumTable}
            coordTable={coordTable}
          />
        ) : (
          <BeobachtungPrivateDesktop
            observation={observation}
            show={show}
            handleShow={handleShow}
            handleClose={handleClose}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
            map={map}
            mapLinks={mapLinks}
            regionTable={regionTable}
            naturraumTable={naturraumTable}
            coordTable={coordTable}
          />
        )
      ) : (
        <Loading />
      )}
    </>
  );
}
