import React from "react";
import Table from 'react-bootstrap/Table';

function round(x, decimals = 0) {
  return Math.round(10 ** decimals * x) / (10 ** decimals)
};

export default function KoordinatenDetails({ latitude, longitude, hoehe, unschaerfe }) {

  const latitudeRounded = round(latitude, 5);
  const longitudeRounded = round(longitude, 5);

  return (
    <>
      <Table responsive hover size="sm">
        <thead>
          <tr>
            <td>Längengrad</td>
            <td>Breitengrad</td>
            <td>Höhe (in m)</td>
            <td>Unschärfe (in m)</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{longitudeRounded}</td>
            <td>{latitudeRounded}</td>
            <td>{hoehe}</td>
            <td>{unschaerfe}</td>
          </tr>
        </tbody>
      </Table>
    </>
  )
}
